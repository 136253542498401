import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import { Button } from "../../../components/Button/Button";
import { BlogPost } from "./BlogPost/BlogPost";
import { EditModal } from "./EditModal/EditModal";

import api from "../../../api";
import styles from "./BlogsView.module.scss";
import { useAuth } from "../../../contexts/auth-context";

import { Category } from "../../../models/Category";
import { Blog } from "../../../models/Blog";

interface BlogsViewProps {}

export const BlogsView: React.FunctionComponent<BlogsViewProps> = () => {
  const { isLoggedIn, setIsLoggedIn } = useAuth();

  const [categories, setCategories] = useState<Category[]>([]);
  const [category, setCategory] = useState<Category>();
  const [posts, setPosts] = useState<Blog[]>([]);
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState<Blog | null>(null);

  const fetchCategories = async () => {
    try {
      const response = await api.categories.fetchMainCategories();
      if (response.status === 200) {
        const data = await response.json();
        setCategories(data);
        setCategory(data[0]);
      } else {
        setIsLoggedIn(false);
        history.push("/prijava");
      }
    } catch (err) {}
  };

  const fetchPosts = async (id: string) => {
    try {
      const response = await api.blogs.fetchBlogs(id);
      const data = await response.json();
      if (response.status === 200) {
        setPosts(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onCategoryClick = (value: Category) => {
    setCategory(value);
  };

  const onPostToggle = async (id: number) => {
    const response = await api.blogs.toggleActive(id);
    if (response.status === 401) {
      setIsLoggedIn(false);
      history.push("/prijava");
    }
  };

  const onPostDelete = async (id: number) => {
    const response = await api.blogs.deleteBlog(id);
    if (response.status === 401) {
      setIsLoggedIn(false);
      history.push("/prijava");
    } else {
      const updatedPosts = posts.filter((post) => {
        if (post.id !== id) return post;
      });

      setPosts(updatedPosts);
    }
  };

  const onAddBlogClick = () => {
    history.push("./blogovi/novi-clanak");
  };

  const onModalClose = () => {
    setIsModalOpen(false);
    setSelectedBlog(null);
  };

  const onPostEdit = (blog: Blog) => {
    setIsModalOpen(true);
    setSelectedBlog(blog);
  };

  useEffect(() => {
    if (!isLoggedIn) history.push("/prijava");
    else fetchCategories();
  }, []);

  useEffect(() => {
    if (isLoggedIn && category) fetchPosts("" + category?.id ?? "0");
  }, [category]);

  return (
    <div className={styles.blogsView}>
      <div className={styles.header}>
        <h1>Blogovi</h1>
        <Button
          type="primary"
          className={styles.newBlogButton}
          onClick={onAddBlogClick}
        >
          Novi članak
        </Button>
      </div>

      <ul className={styles.categories}>
        {categories?.map((c) => {
          return (
            <li
              className={classNames(category?.id === c.id ? styles.active : "")}
              onClick={() => onCategoryClick(c)}
            >
              {c.naziv.replace("_", " ")}
            </li>
          );
        })}
      </ul>

      <div className={styles.blogs}>
        {posts.map((post) => {
          return (
            <BlogPost
              blog={post}
              onChange={onPostToggle}
              onDelete={onPostDelete}
              onEdit={onPostEdit}
            />
          );
        })}
      </div>

      <EditModal
        open={isModalOpen}
        handleClose={onModalClose}
        blog={selectedBlog}
      />
    </div>
  );
};
