import {
  fetchAll,
  search,
  fetchById,
  applyForJob,
  scheduleAppointment,
  fetchPremium,
  newsletter,
  fetchAllForAdmin,
} from "./services/konkursi";
import {
  fetchBlogs,
  fetchBlog,
  postBlog,
  fetchBlogPosts,
  fetchMedia,
  deleteBlog,
  editBlog,
  toggleActive,
  applyForTraining,
} from "./services/blogs";
import { fetchMainCategories, fetchSubcategories } from "./services/categories";
import {
  searchPravnaLica,
  addImage,
  fetchSponsoredCompanies,
  addSponsoredCompany,
  deleteSponsoredCompany,
  editSponsoredCompany,
} from "./services/pravna-lica";

const api = {
  konkursi: {
    fetchAll,
    search,
    fetchById,
    applyForJob,
    scheduleAppointment,
    fetchPremium,
    newsletter,
    fetchAllForAdmin,
  },
  blogs: {
    fetchBlogs,
    fetchBlog,
    postBlog,
    fetchBlogPosts,
    fetchMedia,
    toggleActive,
    deleteBlog,
    editBlog,
    applyForTraining,
  },
  categories: {
    fetchMainCategories,
    fetchSubcategories,
  },
  pravnaLica: {
    searchPravnaLica,
    addImage,
    fetchSponsoredCompanies,
    addSponsoredCompany,
    deleteSponsoredCompany,
    editSponsoredCompany,
  },
};

export default api;
