// Libs
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import api from "../../api";

// Components
import { Button } from "../../components/Button/Button";
import { Divider } from "../../components/Divider/Divider";
import { JobCard } from "../../components/JobCard/JobCard";
import { JobGroup } from "../../components/JobGroup/JobGroup";
import { LoadingFallback } from "../../components/LoadingFallback/LoadingFallback";
import { LogoSlider } from "../../components/LogoSlider/LogoSlider";
import { PublicJobCard } from "../../components/PublicJobCard/PublicJobCard";
import { Search } from "../../features/Search/Search";
import { Footer } from "../../layouts/Footer/Footer";
import { Konkurs } from "../../models/Konkurs";

import styles from "./LandingPage.module.scss";

// Assets
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";
import { previewImage } from "../../constants";
import { Logo } from "../../api/services/pravna-lica";
import { PremiumJobs } from "../../containers/PremiumJobs";

export const LandingPage: React.FunctionComponent = () => {
  const [konkursi, setKonkursi] = useState<Konkurs[][]>([[]]);
  const [javniKonkursi, setJavniKonkursi] = useState<Konkurs[]>([]);
  const [premium, setPremium] = useState<Konkurs[]>([]);

  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [gornjaTraka, setGornjaTraka] = useState<Logo[]>([]);
  const [donjaTraka, setDonjaTraka] = useState<Logo[]>([]);

  const fetchPremium = useCallback(async () => {
    try {
      const response = await api.konkursi.fetchPremium();

      if (response.status === 200) {
        const data = await response.json();
        setPremium(data);
        return data;
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const fetchKonkursi = useCallback(async (type: string) => {
    setLoading(true);
    try {
      const response = await api.konkursi.fetchAll(type);

      if (response.status === 200) {
        const data = await response.json();
        setLoading(false);
        return data;
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const searchHandler = async (query: string, location: string) => {
    const response = await api.konkursi.search(query, location);
    const data: Konkurs[] = await response.json();

    const konkursiFiltered = data.filter((e) => e.tip !== 3);
    const javniFiltered = data.filter((e) => e.tip === 3);

    const temp = konkursiFiltered.map((e) => {
      return new Array(e);
    });

    setJavniKonkursi(javniFiltered);
    setKonkursi(temp);
  };

  const searchClearedHandler = () => {
    fetchKonkursi("privatni?size=20").then((data) => {
      setKonkursi(data);
    });

    fetchKonkursi("javni?size=12").then((data) => {
      setJavniKonkursi(data);
    });
  };

  const fetchTraka = async () => {
    let response = await api.pravnaLica.fetchSponsoredCompanies(1);
    let data = await response.json();
    setGornjaTraka(data);

    response = await api.pravnaLica.fetchSponsoredCompanies(2);
    data = await response.json();

    setDonjaTraka(data);
  };

  const navigate = (path: string) => {
    history.push(path);
  };

  const createStructuredJson = () => {
    let json = {
      "@context": "https://schema.org",
      "@type": "WebSite",
      name: "Agencija Spektar",
      url: window.location.href,
      copyrightYear: 2023,
      creator: "Agencija Spektar",
      keywords:
        "posao,zaposlenje,banja luka,banjaluka,zaposli se,tražim posao,radnici,poslodavci,bih,konkursi za posao,sezonski poslovi,inostranstvo,posao ponuda,ponuda poslova,posao od kuće,bosna i hercegovina,oglasi za posao,posao banja luka,honorarni posao,intervju,razgovor za posao,savjeti za intervju,savjeti za traženje posla,savjeti za posao,savjeti za razgovor za posao,posao banjaluka",
      thumbnailUrl: previewImage,
      image: previewImage,
      inLanguage: "bs-BA",
      sameAs: [
        "https://www.facebook.com/agencijaspektar",
        "https://www.instagram.com/agencijaspektar/",
        "https://www.linkedin.com/in/agencija-spektar/",
        "https://twitter.com/AgencijaSpektar",
        "https://www.youtube.com/@agencijaspektar",
      ],
    };

    return JSON.stringify(json);
  };

  useEffect(() => {
    fetchKonkursi("privatni?size=20").then((data) => {
      setKonkursi(data);
    });

    fetchKonkursi("javni?size=12").then((data) => {
      setJavniKonkursi(data);
    });

    fetchPremium();
  }, [fetchKonkursi, fetchPremium]);

  useEffect(() => {
    fetchTraka();
  }, []);

  return (
    <div className={styles.container}>
      <Helmet>
        <meta http-equiv="refresh" content="900" />

        <title>Agencija Spektar | Posredovanje pri zapošljavanju </title>
        <meta
          property="og:title"
          content="Agencija Spektar | Posredovanje pri zapošljavanju"
        />
        <meta
          name="description"
          content="Najnoviji oglasi za posao. Nađite novi, dodatni ili honorarni posao. "
        />
        <meta name="robots" content="max-image-preview:large" />
        <meta
          name="keywords"
          content="posao,zaposlenje,banja luka,banjaluka,zaposli se,tražim posao,radnici,poslodavci,bih,konkursi za posao,sezonski poslovi,inostranstvo,posao ponuda,ponuda poslova,posao od kuće,bosna i hercegovina,oglasi za posao,posao banja luka,honorarni posao,intervju,razgovor za posao,savjeti za intervju,savjeti za traženje posla,savjeti za posao,savjeti za razgovor za posao,posao banjaluka"
        />
        <meta property="og:image" content={previewImage} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Agencija Spektar" />
        <script type="application/ld+json">{createStructuredJson()}</script>
      </Helmet>

      <div className={styles.jumbotron}>
        {!!premium.length && <PremiumJobs konkursi={premium} />}
        <Search
          className={styles.search}
          onSearch={searchHandler}
          onClear={searchClearedHandler}
        />
      </div>

      <div className={styles.content}>
        <Divider className={styles.divider} />

        <h1 className={styles.title}>Najnoviji konkursi</h1>

        <div className={styles.jobListings}>
          {loading && <LoadingFallback />}

          {!loading && konkursi && konkursi.length === 0 ? (
            <p style={{ margin: "30vh auto" }}>Nema novih konkursa.</p>
          ) : (
            konkursi &&
            konkursi.length > 0 &&
            konkursi.map((nested) => {
              return nested.length > 1 ? (
                <JobGroup
                  konkursi={nested}
                  key={nested.length + Math.random()}
                />
              ) : (
                nested.map((e) => {
                  return <JobCard konkurs={e} key={e.id} />;
                })
              );
            })
          )}
        </div>

        <div className={styles.more}>
          <Button
            type="link"
            className={styles.link}
            onClick={() => navigate("svi-poslovi/1")}
          >
            Prikaži sve
          </Button>
          <Arrow
            style={{
              width: "20px",
              color: "var(--primary)",
            }}
          />
        </div>
      </div>

      <LogoSlider logos={gornjaTraka} />

      <div className={styles.publicContent}>
        <h1 className={styles.title} style={{ margin: "30px 0" }}>
          Oglasi iz sredstava informisanja
        </h1>
        <div className={styles.publicListings}>
          {javniKonkursi && javniKonkursi.length === 0 && (
            <p style={{ margin: "30vh auto" }}>Nema novih konkursa.</p>
          )}
          {javniKonkursi &&
            javniKonkursi.map((e) => {
              return <PublicJobCard konkurs={e} key={e.id} />;
            })}
        </div>

        <div className={styles.more}>
          <Button
            type="link"
            className={styles.link}
            onClick={() => {
              navigate("oglasi-iz-sredstava-informisanja/1");
            }}
          >
            Prikaži sve
          </Button>
          <Arrow
            style={{
              width: "20px",
              color: "var(--primary)",
            }}
          />
        </div>
      </div>

      <div className={styles.section}>
        <LogoSlider direction="right" logos={donjaTraka} />
        <Divider className={styles.dividerRotated} />
      </div>

      <div className={styles.imgReveal}>
        <Footer />
      </div>
    </div>
  );
};
