import Slider from "react-slick";

import { PremiumJobCard } from "../../components/PremiumJobCard";
import styles from "./PremiumJobs.module.scss";
import { Konkurs } from "../../models/Konkurs";

interface PremiumJobsProps {
  konkursi: Konkurs[];
}

const SampleNextArrow: React.FunctionComponent<
  React.HTMLAttributes<HTMLDivElement>
> = ({ ...props }) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        right: "3px",
        cursor: "pointer",
        zIndex: 12334,
      }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow: React.FunctionComponent<
  React.HTMLAttributes<HTMLDivElement>
> = ({ ...props }) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        left: "3px",
        cursor: "pointer",
        zIndex: 12334,
      }}
      onClick={onClick}
    />
  );
};

let settings = {
  arrows: true,
  dots: true,
  speed: 1000,
  autoplay: true,
  accessibility: true,
  slidesToShow: 4,
  autoPlay: true,
  autoplaySpeed: 5000,
  slidesToScroll: 1,
  centerPadding: "10px",
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 1224,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 927,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 630,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

export const PremiumJobs: React.FunctionComponent<PremiumJobsProps> = ({
  konkursi,
}) => {
  return (
    <Slider {...settings} className={styles.premiumJobs}>
      {konkursi.map((konkurs) => (
        <PremiumJobCard konkurs={konkurs} key={`premium-${konkurs.id}`} />
      ))}
    </Slider>
  );
};
