// Libs
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

// Components
import { Search } from "../../features/Search/Search";
import { LogoSlider } from "../../components/LogoSlider/LogoSlider";
import { Divider } from "../../components/Divider/Divider";
import { Footer } from "../../layouts/Footer/Footer";
import { Pagination } from "@mui/material";
import { JobsGrid } from "../JobsPage/JobsGrid/JobsGrid";
import { PremiumJobs } from "../../containers/PremiumJobs";

// Utils
import { Konkurs } from "../../models/Konkurs";
import { PageableResponse } from "../../models/PageableResponse";

import api from "../../api";
import styles from "./ArchivePage.module.scss";
import { previewImage } from "../../constants";
import { Logo } from "../../api/services/pravna-lica";

const PAGE_SIZE = 24;

interface PageParams {
  page: string;
}

export const ArchivePage: React.FunctionComponent = () => {
  const { page } = useParams<PageParams>();

  const [konkursi, setKonkursi] = useState<Konkurs[]>([]);
  const [premium, setPremium] = useState<Konkurs[]>([]);

  const [pagination, setPagination] = useState<PageableResponse>();
  const [loading, setLoading] = useState(false);
  const [showPagination, setShowPagination] = useState(true);

  const [logos, setLogos] = useState<Logo[]>([]);

  const history = useHistory();

  const fetchJobs = useCallback(async (page: number, size: number) => {
    setLoading(true);
    try {
      const response = await api.konkursi.fetchAll(
        `arhiva?size=${size}&page=${page}`
      );
      if (response.status === 200) {
        const data = await response.json();
        setPagination(data);
        setKonkursi(data.content);
        setLoading(false);
      }
    } catch (err) {}
  }, []);

  const fetchPremium = useCallback(async () => {
    try {
      const response = await api.konkursi.fetchPremium();

      if (response.status === 200) {
        const data = await response.json();
        setPremium(data);
        return data;
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const fetchTraka = async () => {
    let response = await api.pravnaLica.fetchSponsoredCompanies(1);
    let data = await response.json();
    setLogos(data);
  };

  const searchHandler = async (query: string, location: string) => {
    const response = await api.konkursi.search(query, location, "", true);
    const data: Konkurs[] = await response.json();
    const konkursiFiltered = data.filter((e) => e.idPravnogLica !== null);

    setShowPagination(false);
    setKonkursi(konkursiFiltered);
  };

  const searchClearedHandler = () => {
    fetchJobs(0, PAGE_SIZE);
    setShowPagination(true);
  };

  const handlePageChange = (event: any, page: any) => {
    history.push("/arhiva/" + page);
  };

  useEffect(() => {
    if (!page) history.replace("/svi-poslovi/1");

    fetchJobs(+page - 1, PAGE_SIZE);
  }, [fetchJobs, page, history]);

  useEffect(() => {
    fetchTraka();
    fetchPremium();
  }, []);

  return (
    <div className={styles.container}>
      <Helmet>
        <title>Arhiva poslova | Agencija Spektar</title>
        <meta name="description" content="Agencija Spektar" />
        <meta property="og:title" content="Arhiva poslova | Agencija Spektar" />
        <meta property="og:image" content={previewImage} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Agencija Spektar" />
      </Helmet>

      {!!premium.length && <PremiumJobs konkursi={premium} />}

      <div className={styles.searchSection}>
        <h1 className={styles.searchTitle}>Pretražite arhivu</h1>
        <p className={styles.searchSubtitle}>
          Na ovoj stranici možete pronaći sve trenutno aktivne konkurse, kao i
          konkurse koji su istekli.
        </p>

        <Search
          onSearch={searchHandler}
          onClear={searchClearedHandler}
          className={styles.search}
        />
      </div>

      <div className={styles.content}>
        <div className={styles.jobs}>
          <JobsGrid loading={loading} konkursi={konkursi} tag={true} />
        </div>

        {showPagination && (
          <Pagination
            page={+page}
            count={pagination?.totalPages}
            color="primary"
            shape="rounded"
            variant="outlined"
            style={{ paddingBottom: "10vh" }}
            onChange={handlePageChange}
          />
        )}
      </div>

      <LogoSlider logos={logos} />
      <div className={styles.imgReveal}>
        <Divider className={styles.dividerRotated} />
        <Footer />
      </div>
    </div>
  );
};
