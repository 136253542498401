import { get, post, put, remove } from "../client";

const requestOptions = {
  headers: {
    authorization: "Basic " + process.env.REACT_APP_AUTH_KEY,
  },
};

const fetchBlogs = async (id: string) => {
  const request = await get(
    process.env.REACT_APP_BACKEND_URL + `clanci?idKategorije=${id}`,
    requestOptions
  );

  return request;
};

const fetchBlog = async (id: string) => {
  const request = await get(
    process.env.REACT_APP_BACKEND_URL + `clanci/${id}`,
    requestOptions
  );

  return request;
};

const postBlog = async (body: FormData) => {
  const response = await post(
    process.env.REACT_APP_BACKEND_URL + "admin/clanak",
    { credentials: "include", body }
  );
  return response;
};

const fetchBlogPosts = async (page?: number, size?: number) => {
  const response = await get(
    process.env.REACT_APP_BACKEND_URL + `clanci/blog?page=${page}&size=${size}`,
    requestOptions
  );

  return response;
};

const applyForTraining = async (id: string, body: FormData) => {
  const response = await post(
    process.env.REACT_APP_BACKEND_URL + `clanci/obuke/${id}`,
    { ...requestOptions, body }
  );
  return response;
};

const fetchMedia = async (page?: number, size?: number) => {
  const response = await get(
    process.env.REACT_APP_BACKEND_URL +
    `clanci/mediji?page=${page}&size=${size}`,
    requestOptions
  );

  return response;
};

const toggleActive = async (id: number) => {
  const response = await get(
    process.env.REACT_APP_BACKEND_URL + `admin/clanak/toggle/${id}`,
    { credentials: "include" }
  );
  return response;
};


const deleteBlog = async (id: number) => {
  const request = await remove(
    process.env.REACT_APP_BACKEND_URL + `clanci/${id}`,
    { credentials: "include" }
  );
  return request;
};

const editBlog = async (body: FormData) => {
  const response = await put(
    process.env.REACT_APP_BACKEND_URL + "admin/clanak",
    { credentials: "include", body }
  );
  return response;
};

export {
  fetchBlogs,
  fetchBlog,
  postBlog,
  fetchBlogPosts,
  fetchMedia,
  toggleActive,
  deleteBlog,
  editBlog,
  applyForTraining,
};
