// Libs
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./config/query-client";
import { CookiesProvider } from "react-cookie";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./index.scss";
import App from "./app/App";

// Contexts
import { SelectionContextProvider } from "./contexts/selection-context";
import { AuthContextProvider } from "./contexts/auth-context";

// Google Analytics
import ReactGA from "react-ga4";
const TRACKING_ID = "G-B9STV25VMQ";

ReactGA.initialize(TRACKING_ID);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <SelectionContextProvider>
      <QueryClientProvider client={queryClient}>
        <CookiesProvider>
          <AuthContextProvider>
            <App />
          </AuthContextProvider>
        </CookiesProvider>
      </QueryClientProvider>
    </SelectionContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
