// Libs
import { useState } from "react";
import classNames from "classnames";

// Components
import { Button } from "../../components/Button/Button";
import Input from "../../components/Input/Input";

import styles from "./Search.module.scss";

export interface SearchProps {
  className?: string;
  onSearch: (query: string, location: string) => void;
  onClear: () => void;
}

export const Search: React.FunctionComponent<SearchProps> = ({
  className = "",
  onSearch,
  onClear,
}) => {
  const [queryClear, setQueryClear] = useState(false);
  const [locationClear, setLocationClear] = useState(false);
  const [query, setQuery] = useState("");
  const [location, setLocation] = useState("");

  const clearQueryFiled = () => {
    setQuery("");
    setQueryClear(false);
    onClear();
  };

  const clearLocationField = () => {
    setLocation("");
    setLocationClear(false);
    onClear();
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      if (query === "" && location === "") onClear();
      else onSearch(query, location);
    }
  };

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.search}>
        <Input
          onChange={(e) => {
            setQueryClear(true);
            if (e.target.value === "") setQueryClear(false);
            setQuery(e.target.value);
          }}
          onKeyDown={handleKeyDown}
          value={query}
          className={styles.input}
          placeholder="Pojam za pretragu"
          type="search"
        />
        <div
          className={classNames(
            styles.clear,
            queryClear ? styles.show : styles.hide
          )}
          onClick={clearQueryFiled}
        ></div>
      </div>
      <div className={styles.location}>
        <Input
          onChange={(e) => {
            setLocationClear(true);
            if (e.target.value === "") setLocationClear(false);
            setLocation(e.target.value);
          }}
          onKeyDown={handleKeyDown}
          value={location}
          className={styles.input}
          placeholder="Lokacija"
          type="location"
        />
        <div
          className={classNames(
            styles.clear,
            locationClear ? styles.show : styles.hide
          )}
          onClick={clearLocationField}
        ></div>
      </div>

      <div className={styles.action}>
        <Button
          type="primary"
          className={styles.button}
          onClick={() => onSearch(query, location)}
        >
          Pretraži
        </Button>
      </div>
    </div>
  );
};
